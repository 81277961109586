<template>
  <div
    v-if="discountValue"
    :class="[
      type == 'big' ? 'shein-club-discount' : 'shein-club-discount_small',
    ]"
  >
    <template v-if="type == 'big'">
      <div class="shein-club-discount__logo">
        <img
          :src="`${publicCdn}/she_dist/images/pay_vip/pay_vip_member_name@5x-9b50cbcf29.png`"
        />
      </div>
      <div class="shein-club-discount__discount">
        <div>-{{ discountValue }}%</div>
      </div>
    </template>
    <template v-else>
      <img
        :src="`${publicCdn}/she_dist/images/pay_vip/sui_icon_paidmember_Label_s@5x-5dbacb188f.png`"
      />
      <div class="shein-club-discount__discount">
        <div>-{{ discountValue }}%</div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ProductItemSheinClubDiscount',
  props: {
    discountValue: {
      type: [String, Number],
      default: '',
    },
    publicCdn: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'big',
    },
  },
}
</script>

<style lang="less">
.shein-club-discount {
  position: relative;
  display: inline-block;
  height: 18px;
  line-height: 18px;
  font-size: 12px;
  margin-top: 6px;
  background: #ffe2d3;
  border-radius: 1px;
  padding-left: 81px/* rtl:ignore */;
  direction: ltr/* rtl:ignore */;
  &_small {
    position: relative;
    display: inline-block;
    height: 16px;
    line-height: 16px;
    margin-top: 3px;
    font-size: 12px;
    padding-left: 18px /* rtl:ignore */;
    direction: ltr /* rtl:ignore */;
    > img {
      position: absolute;
      left: 0 /* rtl:ignore */;
      top: 0;
      width: 18px;
      height: 16px;
    }
    .shein-club-discount {
      &__discount {
        text-align: center;
        background: #ffe2d3;
        color: #873c00;

        > div {
          transform: scale(0.8333);
        }
      }
    }
  }
  .shein-club-discount__logo {
    color: #873c00;
    margin: 0 4px 0 15px;
    > img {
      position: absolute;
      left: 0 /* rtl:ignore */;
      top: 50%;
      transform: translateY(-50%);
      width: 81px;
    }
  }
  &__discount {
    text-align: center;
    background: #873c00;
    color: #ffe2d3;
    padding: 0 2px;
    border-radius: 0 1px 1px 0 /* rtl:ignore */;
    > div {
      transform: scale(0.8333);
    }
  }
}
</style>
