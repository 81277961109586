/**
 * 根据商品项配置生成商品的原子请求字段信息（尽量考虑以后多场景用同一个方法生成，所以不要增加场景个性化的字段）
 * 修复时需同步服务端版本 services/product_list/getAtomRequestFields.js
 * @param {Object} itemConfig 商品项的配置
 * @param {Array} filedList 额外自己控制的请求字段，不依赖商品项的？
 */
export const getAtomicRequestFileds = (itemConfig = {}, filedList = [], isRecommend = false) => {

  const { 
    showPromoLabel, showSheinClubDiscountValue, showAddWishlist, showNewFlashPrice, showMultiColor,
    showSeriesBrand, showPromotion, showBadge, showQuickShip, showSellingPoint, showNewFlashPriceOnSale, flashZonePromotion,
    locateLabels, showBeltLabel, showEstimateTag, showEstimatedPriceReachThreshold, showPlusSize, showNewFlashLabel, 
    showRankingList, showUserBehaviorLabel, showDecisionAttr, showStarComment, showPriceDiscountDesc, showSheinClubLabelInServicesLabel,
    showUserCommentKeywordsLabel, showEstimatedPriceOnSale, showPropertyUpsell, showHorseRaceLabel,
  } = itemConfig
  

  const realTimeTspLabels = { machine_label: [] }
  // if (showFollowLabel) realTimeTspLabels.machine_label.push('3658')

  const listExtField = []
  if (showMultiColor) listExtField.push('relatedProducts')  // 多色块
  if (flashZonePromotion) listExtField.push('flashZonePromotion')  // 分人群专区闪购
  if (showPromotion || showSheinClubDiscountValue) listExtField.push('promotion') // 促销信息
  // if (showLeftStock) listExtField.push('stock')                                   // 库存信息
  // if (showUserAct) listExtField.push('tspLabels')                                 // tsp各种角标
  if (!showPlusSize) listExtField.push('plusSize')                                // 大尺码
  // if (showCropImg) listExtField.push('realTimeTspLabels')                         // 裁剪需要传的原子服务字段
  if (showSellingPoint) listExtField.push('sellingPoints')                        // 卖点
  if (showSeriesBrand) listExtField.push('seriesAndBrand')                        // 品牌系列
  if (showBadge) listExtField.push('cccTspBadges')                                // ccc 角标
  if (showSheinClubDiscountValue || showSheinClubLabelInServicesLabel) {
    listExtField.push('sheinClubPromotionInfo')
    listExtField.push('vipDiscountPrices')
  }       // 付费会员 | vip 价格
  if (showQuickShip) listExtField.push('quickShip')                               // 快速发货标签
  if (showNewFlashPrice || showNewFlashPriceOnSale) listExtField.push('newFlashPromotion')                     // 新型闪购
  if (showPromoLabel) listExtField.push('promoLabel')                               // 促销标签
  if (showRankingList) listExtField.push('rankingList')                           // 展示排行榜信息，注意需要在原子服务区分不同场景不同的poskey
  if (showDecisionAttr) listExtField.push('enableDecisionAttr')                   // 角色属性
  if (showUserBehaviorLabel) listExtField.push('userBehaviorLabel')                    // 用户行为标签
  if (showStarComment) listExtField.push(isRecommend ? 'recommendStarComment' : 'starComment') // 星级评论
  if (showPriceDiscountDesc) listExtField.push('priceDiscountDesc')               // 历史低价、保留款跟价款标签
  if (showAddWishlist) listExtField.push('wish')                                    // 是否被收藏
  if (locateLabels) listExtField.push('locateLabels')  
  if (showBeltLabel) listExtField.push('beltLabel') // 腰带
  if (showEstimateTag || showEstimatedPriceReachThreshold || showEstimatedPriceOnSale) listExtField.push('estimatedPrice') // 到手价
  if (showNewFlashLabel) listExtField.push('showNewFlashLabel')                   // 
  if (realTimeTspLabels.machine_label.length) listExtField.push('realTimeTspLabels') 
  if (showUserCommentKeywordsLabel) listExtField.push('userCommentKeywordsLabel') // 用户评论关键词标签
  if (showHorseRaceLabel) listExtField.push('horseRaceLabel')               // 赛马标签
  if (showPropertyUpsell) listExtField.push('cornerPropertyUpsell')               // 属性类卖点标签

  // 请求字段的列表
  const requestMenuList = filedList.length ? [ ...new Set(listExtField.concat(filedList)) ] : listExtField
  // const { detailCropA, detailCropB } = cropImgParams || {}
  // 请求的字段
  return requestMenuList.reduce((pre, curKey) => {
    if (curKey === 'realTimeTspLabels') {
      pre[curKey] = realTimeTspLabels
    } else if (curKey === 'tspLabels') {
      // 用户行为标签
      pre[curKey] = 'c7d_click_pv,c7d_wishlist_uv,c7d_sale_cnt,eval_cnt'
    } else {
      pre[curKey] = true
    }
    return pre
  }, {})
}
