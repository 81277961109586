import schttp from 'public/src/services/schttp'
import i18n from 'public/src/pages/common/biz_helper/i18n'
import PROMOTION_CONFIG from 'public/src/pages/common/promotion/config.js'
import { dealProductsPretreatInfo } from 'public/src/services/goodsItemInfo/goodsPretreatInfo.js'

// 商详用-新商卡客户端预处理
export async function getDetailRecPreTreat({ products = [], itemConfig }) {
  if (!products.length) {
    return []
  }

  // 防止请求体过大
  const _products = products.reduce((list, item) => {
    if (!item.promotionInfo?.length) return list
    list.push({
      promotionInfo: item.promotionInfo?.map((info) => ({ id: info.id, typeId: info.typeId })),
    })
    return list
  }, [])

  const language = await i18n.loadByQueue('promotion')

  // 实时获取促销信息
  let promotionInfoFromServer = {}
  if (_products.length) {
    promotionInfoFromServer = await schttp({
      method: 'POST',
      url: '/api/productInfo/postIntegrityPromotionInfo/get',
      data: {
        asyncPromotionIds: PROMOTION_CONFIG.FetchPromotionLableTypeIds.join(),
        fetchedPromotionIds: Object.keys(
          window.promotionCenter?.cache.integrityPromotionInfo || {}
        ).join(),
        products: _products,
      },
    })
  }
  const res = dealProductsPretreatInfo({
    products,
    itemConfig,
    promotionInfoFromServer,
    language,
  })
  return res
}
