import { abtservice } from 'public/src/services/abt'

export const getRecAbtResult = {
  done: false, // 是否已经请求结束
  fetch: false, // 是否正在请求
  poskey: ['SellingPoint', 'AllListQuickShip'],
  newPoskey: ['FollowLabel', 'discountLabel', 'greysellingPoint', 'sheinappwordsize', 'goodsSpace', 'listtagSorting', 'listrankingTag', 'CccxPcPicSize'],
  show: {
    showSellingPoint: false,
    showQuickShip: false,
    showBadge: false,
    showNewDiscountLabel: false,
    showGreySellingPoint: false
  },
  awaitQueue: [], // 请求结果后的回调队列
  fetchAbt(cb, listType) {
    // 是否已经请求过了
    if (getRecAbtResult.done) return cb(getRecAbtResult.show)
    // 是否有正在请求的
    if (getRecAbtResult.fetch) {
      getRecAbtResult.awaitQueue.push(cb)
      return
    }
    let isCartSaveItems = listType === 'cart-saved-items'
    getRecAbtResult.fetch = true // 正在请求
    abtservice.getUserAbtResult({ 
      posKeys: getRecAbtResult.poskey.join(),
      newPosKeys: getRecAbtResult.newPoskey.join(),
    }).then(result  => {
      getRecAbtResult.show.showFollowLabel = result.FollowLabel?.p?.ShowFollowLabel === 'show'
      getRecAbtResult.show.showSellingPoint = result['SellingPoint']?.param == 'type=sellingpoint'
      getRecAbtResult.show.showQuickShip = result['AllListQuickShip']?.param == 'type=B'
      getRecAbtResult.show.showBadge = true
      getRecAbtResult.show.showNewDiscountLabel = result?.['discountLabel']?.param?.['discountLabel'] == 'Label'
      getRecAbtResult.show.showGreySellingPoint = result?.['greysellingPoint']?.param?.['greysellingPoint'] == 'grey'
      getRecAbtResult.show.showSheinappwordsize = isCartSaveItems && result?.['sheinappwordsize']?.param?.['sheinappwordsize'] == 'newprice'
      getRecAbtResult.show.showGoodsSpace = isCartSaveItems && result?.['goodsSpace']?.param?.['goodsSpace'] == 'newSpace'
      getRecAbtResult.show.showHorseRaceLabel = !!result?.['listtagSorting']?.param?.['tagsortingContent']
      getRecAbtResult.show.showPclistCropping = false
      getRecAbtResult.show.picSize = result?.['CccxPcPicSize']?.param?.CccxPcPicSize
      getRecAbtResult.done = true
      getRecAbtResult.fetch = false // 正在结束
      // 执行回调
      cb(getRecAbtResult.show)
      // 如果有队列，执行队列
      if (getRecAbtResult.awaitQueue.length) {
        getRecAbtResult.awaitQueue.forEach((_) => {
          _(getRecAbtResult.show)
        })
      }
    })
  }
}
